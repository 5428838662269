<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="fit-content"
    custom-class="contract-type"
    v-on="$listeners">
    <div class="type-container">
      <span>I am creating a:</span>
      <div class="btn">
        <el-button
          style="width: 200px"
          type="primary"
          round
          @click="clickHandler(2)">
          Loading Schedule
        </el-button>
        <el-button
          style="width: 200px"
          type="primary"
          round
          @click="clickHandler(1)">
          Delivery Schedule
        </el-button>
        <el-button
          style="width: 200px"
          type="primary"
          round
          @click="clickHandler(3)">
          Ship to Ship (STS)
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'SelectScheduleType',
  data () {
    return {
      dialogVisible: false
    }
  },
  mounted () {
    this.dialogVisible = true
  },
  methods: {
    clickHandler (type) {
      this.$emit('selected', type)
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.contract-type {
  .type-container {
    @include flex-center-column;

    > span {
      font-size: 20px;
    }

    .btn {
      display: flex;
      flex-wrap: wrap;
      padding: 20px;
    }
  }

}
</style>
